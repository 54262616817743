* {
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --grey: #f1f4f8b0;
  --dark-grey: #6b7c93;
  --green: #24b47e;
  --teal: #4F96CE;
  --blue: #6772e5;
  --dark-blue: #4F3EF5;
  --spacer: 28px;
}

body {
  font-family: -apple-system, system-ui, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: var(--dark-grey);
}

a {
  text-decoration: none;
  color: var(--blue);
}

a:hover,
a:focus {
  color: var(--dark-blue);
}

ul {
  list-style: none;
  padding-left: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}
